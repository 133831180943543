/* MatrixBackground.css */
@keyframes fall {
    0% {
        transform: translateY(-150%);
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        transform: translateY(40vh);
        opacity: 1;
    }
}

.logo-background {
    position: relative;
    overflow: hidden;
    background-image: url('../../public/images/background.svg');
    background-size: cover;
    background-position: center;
}

.falling-text {
    position: absolute;
    top: 0;
    font-size: 1rem;
    color: rgba(255, 150, 0, 0.8);
    animation: fall linear infinite;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}
