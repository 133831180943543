@font-face {
    font-family: 'DPComic';
    src: url('../public/fonts/dpcomics.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@tailwind base;
@tailwind components;
@tailwind utilities;


.fade-in-animation {
    animation: fadeInCone 2s forwards;
}

@keyframes fadeInCone {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.bg-radial-cone {
    background: radial-gradient(circle at center bottom, rgba(247, 147, 26, 0.6) 0%, rgba(247, 147, 26, 0.2) 20%, transparent 40%);
    transform-origin: center bottom;
}

@keyframes bounce-slow {
    0%, 100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.animate-bounce-slow {
    animation: bounce-slow 3s infinite;
}
